export default {
    apiEndpoint: 'https://vps-zalogmax.s4w.com.hr/api/v1/',
    appEndpoints: {
        root: '/',
        odabirPoslovnice: '/poslovnica',
        rezervacije: '/rezervacije',
        novaRezervacija: '/rezervacije/nova',
        importRezervacija: '/rezervacije/import',
        otkup: '/otkup',
        primka: '/primka',
        prodaja: '/prodaja',
        procjena: '/procjena',
        radniNalog: '/radninalog',
        meduskladisnica: '/meduskladisnica',
        dokumentiRoot: '/dokumenti',
        klijenti: '/klijenti',
        artikli: '/artikli',
        racuni: '/racuni',
        predracuni: '/predracuni',
        radniNalozi: '/radninalozi',
        procjene: '/procjene',
        otkupi: '/otkupi',
        primke: '/primke',
        meduskladisnice: '/meduskladisnice',
        otkupniBlokovi: '/otkupniblokovi',
        stanjeSkladista: '/stanjeskladista',
        usluge: '/usluge',
        postavke: '/postavke',
        upravljanjeFirmom: '/upravljanjefirmom',
        upravljanjeKorisnicima: '/upravljanjekorisnicima',
        upravljanjePostotcima: '/upravljanjepostotcima',
        kartice: '/kartice',
        kategorije: '/kategorije',
        mjerneJedinice: '/mjernejedinice',
        porezniZakoni: '/poreznizakoni',
        izdatnice: '/izdatnice',
        novaIzdatnica: '/novaizdatnica',
        cijeneOglasavanje: '/cijeneioglasavanje',
        maknutiSOglasavanja: '/maknutisoglasavanja',
        ponavljajuciTroskovi: '/ponavljajucitroskovi',
        grupeJednokratnihTroskova: '/grupejednokratnihtroskova',
        blagajnickiDnevnikOtkup: '/blagajnickidnevnikotkup',
        blagajnickiDnevnikFisk: '/blagajnickidnevnikfiskalnablagajna',
        dnevnaEvidencija: '/dnevnaevidencija',
        pregledTroskova: '/pregledtroskova',
        pregledBankovnihTransakcija: '/pregledbankovnihtransakcija',
        rekapitulacije: '/rekapitulacije',
        kontrola: '/kontrola',
        print: {
            potvrdaRezervacije: '/print/potvrdarezervacije/',
            ugovorOtkupa: '/print/ugovorotkupa/',
            primka: '/print/primka/',
            otkupniBlok: '/print/otkupniblok/',
            racun: '/print/racun/',
            izdatnica: '/print/izdatnica/',
            predracun: '/print/predracun/',
            zapisnikOProdajiIspodNabavneCijene: '/print/zapisnikoprodajiispodnabavnecijene/',
            zahtjevZaProcjenom: '/print/zahtjevzaprocjenom/',
            radniNalog: '/print/radninalog/',
            meduskladisnica: '/print/meduskladisnica/',
            stanjeSkladista: '/print/stanjeskladista/',
            stanjeSkladistaInventura: '/print/stanjeskladistainventura/',
            ceduljicascijenom: '/print/ceduljicascijenom/',
            blagajnickiDnevnik: '/print/blagajnickidnevnik/',
            rekapitulacijaRacuna: '/print/rekapitulacijaracuna/',
            rekapitulacijaOtkupnihBlokova: '/print/rekapitulacijaotkupnihblokova/'
        }
    }
};
